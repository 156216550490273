<template>
  <div>
    <div class="row align-items-center">
      <div style="width: 100%">
        <b-form @submit.prevent="searchPoster" class="form-select bg-img-none mt-4">
          <div class="d-flex">
            <b-input
              type="text"
              v-model="searchTerm"
              placeholder="Pesquise a imagem relacionada"
            ></b-input>
            <b-button type="submit" size="sm" class="ml-2">Buscar</b-button>
          </div>
        </b-form>
        <Card>
          <table class="custom-table text-white">
            <tr>
              <th>Programas</th>
              <th>Emissoras</th>
              <th class="text-center">Ações</th>
            </tr>
            <tr v-for="item in results" :key="item.id" class="table-row">
              <td class="program-title">
                {{ item.title }}
                <div class="popup" v-if="item.posterURL">
                  <img :src="item.posterURL" class="posterImage" alt="Poster de programa">
                </div>
              </td>
              <td>{{ item.channel }}</td>
              <td v-if="item.posterURL" class="text-center">
                <a
                  :href="item.posterURL"
                  target="_blank"
                  class="tim-icons icon-cloud-download-93 text-white"
                ></a>
              </td>
              <td v-else class="text-center">Sem imagem.</td>
            </tr>
          </table>
        </Card>
        <div id="paginationPosters">
         <button v-for="pageNumber in totalPages" :key="pageNumber" @click="changePage(pageNumber)">
            {{ pageNumber }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Cards/Card.vue';

export default {
  components: {
    Card,
  },
  data() {
    return {
      searchTerm: '',
      perPage: 32,
      currentPage: 1,
      totalPages: 0,
      results: [],
    };
  },
  methods: {
    async searchPoster() {
      const response = await this.$store.dispatch('searchPoster', {
        searchTerm: this.searchTerm,
        perPage: this.perPage,
        currentPage: this.currentPage,
      });
      this.results = response.data;
      this.totalPages = response.meta.totalPages;
      this.currentPage = response.meta.currentPage;
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.searchPoster();
    },
  },
};
</script>

<style lang="scss">

.row {
  margin-top: -1%;
  margin-left: 1.5%;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    border-bottom: 1px solid rgba(241, 237, 237, 0.753);
    padding: 8px;
    text-align: left;
  }
}

.program-title {
  cursor: pointer;
  position: relative;

  .popup {
    position: absolute;
    top: 50%;
    margin-top: -150px;
    left: 200px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: none;

    .posterImage {
      height: 200px;
      display: block;
    }
  }

  &:hover .popup {
    display: block;
  }
}
</style>
